export type EPIType = 'co2' | 'kwh' | 'euro';

export const useEPI = () => {
  // COmposables

  const getEPIType = (): { key: EPIType; label: string }[] => {
    return [
      {
        key: 'kwh',
        label: 'kWh',
      },
      {
        key: 'euro',
        label: '€',
      },
      {
        key: 'co2',
        label: 'CO2',
      },
    ];
  };

  const getNewEPIType = (): { key: string; label: string }[] => {
    return [
      {
        key: 'epi',
        label: 'kWh',
      },
      {
        key: 'cost_by_quantity',
        label: '€',
      },
      {
        key: 'co2',
        label: 'gCO2',
      },
    ];
  };

  return { getEPIType, getNewEPIType };
};
