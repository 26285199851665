import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import type { ProductsQueryListQuery } from '~/types/graphql/graphql';

export const useProduct = () => {
  // Composable
  const { formatNumberToIsoNumber } = useNumbers();

  // Data
  const seletedUnit = ref<'cost_by_quantity' | 'epi' | 'co2'>('epi');

  const getLastProduction = (item: ProductsQueryListQuery['products'][0]) => {
    const productionDates: string[] = [];
    item.fabrication_order_products.forEach((fabricationOrdersProducts) => {
      if (fabricationOrdersProducts.fabrication_order?.start) {
        productionDates.push(fabricationOrdersProducts.fabrication_order?.start);
      }
    });
    const lastProductionDate = productionDates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    return productionDates.length > 0 ? dayjs(lastProductionDate[0]).locale('fr').format('ddd DD MMM YYYY') : '--';
  };

  const getLastProductionToUnix = (item: ProductsQueryListQuery['products'][0]) => {
    const productionDates: string[] = [];
    item.fabrication_order_products.forEach((fabricationOrdersProducts) => {
      if (fabricationOrdersProducts.fabrication_order?.start) {
        productionDates.push(fabricationOrdersProducts.fabrication_order?.start);
      }
    });
    const lastProductionDate = productionDates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    return productionDates.length > 0 ? dayjs(lastProductionDate[0]).valueOf() : dayjs().valueOf();
  };

  const getAverageEPI = (item: ProductsQueryListQuery['products'][0]) => {
    const data: number[] = [];
    const baseUnit = {
      cost_by_quantity: '€ / ',
      epi: 'kWh / ',
      co2: '',
    };
    let unit = baseUnit[seletedUnit.value] + 'kg';

    item.fabrication_order_products
      .filter((fabrication_order) => fabrication_order.fabrication_order.fabrication_order_epis[0]?.consumption_kwh > 0)
      .map((fabrication_order) => {
        const fabrication_order_epi = fabrication_order.fabrication_order?.fabrication_order_epis[0];

        if (seletedUnit.value === 'co2') {
          const meter_id = fabrication_order_epi?.meter_id;
          const co2 = computed(() => useCO2().getCo2byKWhByMeterId(meter_id));
          const epi = fabrication_order_epi?.epi ?? 0;
          data.push(epi * co2.value);
          unit = fabrication_order.fabrication_order?.area?.fabrication_order_unit?.symbol ?? 'kg';
        } else {
          data.push(fabrication_order_epi?.[seletedUnit.value] ?? 0);
          unit = baseUnit[seletedUnit.value] + (fabrication_order.fabrication_order?.area?.fabrication_order_unit?.symbol ?? 'kg');
        }
      });

    const average = data.reduce((sum, currentValue) => sum + currentValue, 0) / data.length || 0;

    if (seletedUnit.value === 'co2') {
      if (average > 10000) {
        return {
          averageWithUnit: `${formatNumberToIsoNumber(average / 1000)} kgC02 / ${unit}`,
          average: average,
        };
      }
      return {
        averageWithUnit: `${formatNumberToIsoNumber(average)} gC02 / ${unit}`,
        average: average,
      };
    }
    return {
      averageWithUnit: `${average.toFixed(2)} ${unit}`,
      average: average,
    };
  };

  return { getLastProduction, getLastProductionToUnix, getAverageEPI, seletedUnit };
};
